<template>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-10" @close="closeModal()">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-0 z-10 overflow-y-auto">
                <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300"
                        enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                        leave-from="opacity-100 translate-y-0 sm:scale-100"
                        leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel
                            class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div class="mb-6 flex justify-between">
                                <h1 class="font-semibold ">Image Library</h1>
                                <p v-if="imageDocs.length > 0" class="text-blue-600 hover:underline cursor-pointer"
                                    @click="uploadImage()">Upload
                                    image</p>
                            </div>
                            <div v-if="imageDocs.length > 0" class="grid grid-cols-2 gap-2">
                                <div v-for="(image, i) in imageDocs" :key="i" @click="setSelectedImage(image.baseImageUrl)"
                                    class=" cursor-pointer col-span-1 border rounded-md mb-2 h-full w-full overflow-hidden flex flex-col justify-center ">
                                    <img :src="image.imageSrc" />
                                </div>
                            </div>
                            <div v-if="imageDocs.length < 1">No images were found in your image library! Click <span
                                    class="text-blue-600 hover:underline cursor-pointer" @click="uploadImage()">here</span>
                                to upload an image.
                            </div>
                            <div class="mt-5">
                                <button type="button"
                                    class="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                                    @click="closeModal()" ref="cancelButtonRef">Cancel</button>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { collection, query, onSnapshot, where } from "firebase/firestore";

const { $firestore } = useNuxtApp()

const loading = ref(false)

const props = defineProps({
    tenantId: {
        type: String,
        required: true,
    },
    open: {
        type: Boolean,
        required: true,
    },
    imageType: {
        type: String,
        required: true,
    }
})

// Define emits
const emit = defineEmits(["closeImageSelectModal", "imageSelected", "imageUpload"]);
function closeModal() {
    emit("closeImageSelectModal");
}



const { tenantId, open, imageType } = toRefs(props)

// Watch the image library collection 
let imageDocs = ref(null)
let unsubscribe = null
const getImages = async () => {
    loading.value = true
    const db = $firestore
    const imageLibraryCollectionRef = `tenants/${tenantId.value}/imageLibrary`
    const q = query(collection(db, imageLibraryCollectionRef), where("deleted", "!=", true));
    unsubscribe = onSnapshot(q, (querySnapshot) => {
        const images = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data()
            images.push(data)
        });
        imageDocs.value = images

    });
    loading.value = false
}

getImages()

const setSelectedImage = (image) => {
    emit("imageSelected", `${image}/${imageType.value}`)
}

const uploadImage = () => {
    emit("imageUpload")
}

// Stop subscribing to the doc before unmounting the component.
onUnmounted(() => {
    unsubscribe()
})
</script>