
<template>
    <div class="flex-1 xl:overflow-y-auto">
        <ImageUploadModal :open="showUploadImageModal" @close-upload-image-modal="closeModal()"
            @upload-image-success="uploadImageSuccessful()" />
        <ImageSelectModal :tenant-id="tenantId" :open="showImageSelectModal" :image-type="imageType"
            @close-image-select-modal="closeImageSelectModal()" @image-selected="setImage" @image-upload="uploadImage()" />
        <div class="max-w-7xl mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
            <h1 class="text-3xl font-extrabold text-blue-gray-900">Account</h1>
            <div class="border-y py-4 mb-4">
                <h2 class="text-xl font-medium text-blue-gray-900">Profile</h2>
                <p class="mt-1 text-sm text-blue-gray-500">
                    This information will be displayed publicly so be careful what you
                    share.
                </p>
                <div class="grid grid-cols-2 gap-3 mt-4">
                    <div v-if="tenantDoc" class="bg-white p-2 border rounded-md col-span-2 md:col-span-1">
                        <h2 class="text-xl font-semibold text-blue-gray-900">Details</h2>
                        <FormKit type="form" v-model="tenantDoc" @submit="" :submit-attrs="{
                            inputClass: 'hidden',
                        }">
                            <div class="bg-gray-100 p-2 grid grid-cols-2 gap-4 mt-2 ">
                                <div class="col-span-2 sm:col-span-1 md:col-span-2 xl:col-span-1">
                                    <FormKit type="text" name="name" label="Name" />
                                </div>
                                <div class="col-span-2 sm:col-span-1 md:col-span-2 xl:col-span-1">
                                    <FormKit type="email" label="Support Email" name="supportEmail"
                                        validation="required|email" validation-visibility="live"
                                        :placeholder="tenantDoc.supportEmail" />
                                </div>
                            </div>
                            <button @click="saveTenantDetails()"
                                :class="{ 'disable-btn bg-blue-400': loading || isValidEmail() !== true }"
                                class="bg-blue-600 mt-4 shadow-md rounded-md py-1.5 w-full text-white hover:bg-blue-500 hover:shadow-none">
                                <div v-if="loading">
                                    <AnimationSpinner />
                                </div>
                                <div v-if="!loading">Save</div>
                            </button>
                        </FormKit>
                    </div>
                    <div v-if="tenantThemeDoc" class="bg-white rounded-md p-2 border col-span-2 md:col-span-1">
                        <h2 class="text-xl font-semibold text-blue-gray-900">Theme</h2>
                        <FormKit type="form" v-model="tenantThemeDoc" @submit="" :submit-attrs="{
                            inputClass: 'hidden',
                        }">
                            <div class="grid grid-cols-2 gap-4 mt-2">
                                <div class="bg-gray-100 p-2 col-span-2 sm:col-span-1 md:col-span-2 xl:col-span-1">
                                    <FormKit type="text" name="brandName" label="Brand Name" />
                                    <FormKit type="text" name="brandCompanyName" label="Brand Company Name" />
                                </div>

                                <div class="bg-gray-100 p-2 col-span-2 sm:col-span-1 md:col-span-2 xl:col-span-1">
                                    <div class="flex flex-col gap-3 justify-between">
                                        <div>
                                            <h2 class="font-semibold">Brand Logo</h2>
                                            <div class="flex justify-between items-end">
                                                <img :src="tenantThemeDoc.brandLogoUrl" class="w-40 mt-2" />
                                                <p @click="openImageSelectModal('wetixtenantbrandlogo')"
                                                    class="underline text-blue-500 font-light hover:text-blue-700 cursor-pointer">
                                                    Change</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="mt-4 border-t pt-4 flex flex-col gap-3 justify-between">
                                        <div>
                                            <h2 class="font-semibold">Brand Email Header Logo</h2>
                                            <div class="flex justify-between items-end">
                                                <img :src="tenantThemeDoc.brandEmailHeaderLogoUrl" class="w-40 mt-2" />
                                                <p @click="openImageSelectModal('emailheader')"
                                                    class="underline text-blue-500 font-light hover:text-blue-700 cursor-pointer">
                                                    Change</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4 border-t pt-4 flex flex-col gap-3 justify-between">
                                        <div>
                                            <h2 class="font-semibold">Header Logo Image</h2>
                                            <div class="flex justify-between items-end">
                                                <img :src="tenantThemeDoc.headerLogoImageSrc" class="w-40 mt-2" />
                                                <p @click="openImageSelectModal('wetixtenantheaderlogo')"
                                                    class="underline text-blue-500 font-light hover:text-blue-700 cursor-pointer">
                                                    Change</p>
                                            </div>
                                        </div>
                                        <FormKit type="text" name="headerLogoImageAlt" label="Header Logo Image Alt" />
                                    </div>
                                </div>
                                <div class="col-span-2 sm:col-span-1 md:col-span-2 xl:col-span-1">
                                    <div class="bg-gray-100 p-2 flex gap-2 flex-col justify-between">
                                        <FormKit type="color" name="brandColorPrimary" label="Brand Primary Colour"
                                            :value="tenantThemeDoc.brandColorPrimary"
                                            help="Use the colour picker to select the primary brand colour" />
                                        <FormKit type="text" name="brandColorPrimary"
                                            help="Or paste the hex code here if you know it!" />

                                    </div>
                                </div>
                            </div>
                            <button @click="saveTenantThemeChanges()" :class="{ 'disable-btn bg-blue-400': loading }"
                                class="bg-blue-600 mt-4 shadow-md rounded-md py-1.5 w-full text-white hover:bg-blue-500 hover:shadow-none">
                                <div v-if="loading">
                                    <AnimationSpinner />
                                </div>
                                <div v-if="!loading">Save</div>
                            </button>
                        </FormKit>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { doc, updateDoc } from "firebase/firestore";

import { useFirestore } from '@vueuse/firebase/useFirestore'


const tenantId = useActiveTenantId()
const { $firestore } = useNuxtApp()

const loading = ref(false)


const tenantDoc = useFirestore(doc($firestore, `tenants`, `${tenantId}`))
const tenantThemeDoc = useFirestore(doc($firestore, `tenants`, `${tenantId}`, `config`, `theme`))


const { $notify } = useNuxtApp()

const showImageSelectModal = ref(false)
const imageType = ref('')
const setImage = (v) => {
    if (imageType.value === 'wetixtenantbrandlogo') {
        tenantThemeDoc.value.brandLogoUrl = v
    }
    if (imageType.value === 'emailheader') {
        tenantThemeDoc.value.brandEmailHeaderLogoUrl = v
    }
    if (imageType.value === 'wetixtenantheaderlogo') {
        tenantThemeDoc.value.headerLogoImageSrc = v
    }
    imageType.value = ''
    showImageSelectModal.value = false
    return
}
const openImageSelectModal = (v) => {
    imageType.value = v
    showImageSelectModal.value = true
}
const closeImageSelectModal = () => {
    showImageSelectModal.value = false
    imageType.value = ''
}

const uploadImage = () => {
    showImageSelectModal.value = false
    showUploadImageModal.value = true
}

const showUploadImageModal = ref(false)
const closeModal = () => {
    showUploadImageModal.value = false
}

const uploadImageSuccessful = () => {
    showUploadImageModal.value = false
    $notify({
        type: 'success',
        title: "Success",
        message: 'Image uploaded!',
        duration: 2000
    })
    showImageSelectModal.value = true
}

function isValidEmail() {
    const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
    // emailError.value = !emailPattern.test(tenantDoc.value.supportEmail);
    return emailPattern.test(tenantDoc.value.supportEmail) || "Invalid email";
}

const saveTenantDetails = async () => {
    loading.value = true
    const db = $firestore
    try {
        const tenantDocRef = doc(db, "tenants", tenantId);

        await updateDoc(tenantDocRef,
            tenantDoc.value
        );
        $notify({
            type: 'success',
            title: "Success",
            message: 'Details updated!',
            duration: 2000
        })
    }
    catch (e) {
        console.log(e.message)
    }
    loading.value = false
}

const saveTenantThemeChanges = async () => {
    loading.value = true
    const db = $firestore
    try {
        const tenantThemeDocRef = doc(db, "tenants", tenantId, "config", "theme");

        await updateDoc(tenantThemeDocRef,
            tenantThemeDoc.value
        );
        $notify({
            type: 'success',
            title: "Success",
            message: 'Theme updated!',
            duration: 2000
        })
    }
    catch (e) {
        console.log(e.message)
    }
    loading.value = false
}

</script>

<style>
.btn-disabled {
    pointer-events: none;
}
</style>
